@import '../../assets/scss/util/mixins.scss';

.wrapper {
    background: var(--hk-background-base);
    position: fixed;
    width: 100vw;
    height: 100vh;
    opacity: 1;
    z-index: 1;
    @include flex(column, center, var(--hk-spacing-2), center);

    .text {
        @include text(12, 400, var(--hk-color-primary))
    }

    .line {
        overflow: hidden;
        position: relative;
        width: 95px;
        height: 4px;
        background-color: var(--hk-color-complementary);
        border-radius: 36px;
    }

    .line::before {
        content: "";
        left: -50%;
        position: absolute;
        width: 40%;
        height: 4px;
        border-radius: 36px;
        background-color: var(--hk-color-primary);
        animation: line linear infinite 1.5s;
    }
}

.hide {
    animation: hide .75s linear;
    z-index: -1;
    opacity: 0;
}

@supports(height: 100dvh) {
    .wrapper {
        height: 100dvh;
    }
}

@keyframes hide {
    0% {
        opacity: 1;
        z-index: 1;
    }

    90% {
        opacity: 0;
    }

    100% {
        z-index: -1;
    }
}

@keyframes line {
    0% {
        left: -40%;
    }
    
    50% {
        left: 20%;
        width: 80%;
    }

    100% {
        left: 100%;
        width: 100%;
    }
}