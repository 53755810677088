@import '../../assets/scss/util/mixins.scss';

.globalSkills {
    align-self: stretch;
    @include flex(column, flex-start, var(--hk-spacing-2));

    .globaltitle {
        @include text(16, 700, var(--hk-color-primary))
    }
}

.skills {
    @include flex(row, center, var(--hk-spacing-3));

    .content {
        //max-width: 80%;
        @include flex(column);

        .title {
            @include text(24, 700, var(--hk-color-primary));
            @include text-hidden();
        }

        .description {
            //cursor: pointer;
            @include text(14, 400, var(--hk-color-complementary));
            @include text-hidden();
        }
    }

    .stack {
        align-self: stretch;
        @include flex(column, flex-start, var(--hk-spacing-4));
    }
}

@media screen and (max-width: 696px) {
    .info .content {
        max-width: 65%;
    }
}