@import '../../assets/scss/util/mixins.scss';

.info {
    @include flex(row, center, var(--hk-spacing-3));

    .image {
        user-select: none;
        overflow: hidden;
        position: relative;
        display: inline-block;
        @include circle(var(--hk-spacing-2));
        @include flex(row, center, var(--hk-spacing-0), center);

        img {
            @include box(76px);
        }
    }

    .content {
        max-width: 80%;
        @include flex(column);

        .title {
            @include text(24, 700, var(--hk-color-primary));
            @include text-hidden();
        }

        .description {
            cursor: pointer;
            @include text(14, 400, var(--hk-color-complementary));
            @include text-hidden();
        }
    }

    .badges {
        display: flex;
        align-items: center;
    }

    .badge {
        width: 25px;
        height: auto;
        margin-right: 7px;
    }

    .statusIndicator {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #43b581;
    }
}

@media screen and (max-width: 696px) {
    .info .content {
        max-width: 65%;
    }
}