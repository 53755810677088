:root {
    --hk-color-brand: rgba(0, 128, 253, 1);
    --hk-color-white: rgba(255, 255, 255, 1);
}

[data-theme="light"] {
    --hk-background-base: rgba(255, 255, 255, 1);
    --hk-profile-base: rgba(255, 255, 255, 0.5);
    --hk-color-primary: rgba(58, 58, 58, 1);
    --hk-color-complementary: rgba(110, 115, 125, 1);
    --hk-button-base: rgba(255, 255, 255, 1);
    --hk-button-hover: rgba(240, 240, 240, 1);
    --hk-button-active: rgba(230, 230, 230, 1);
}

[data-theme="dark"] {
    --hk-background-base: rgba(9, 9, 11, 1);
    --hk-profile-base: rgba(0, 0, 0, 0.5);
    --hk-color-primary: rgba(255, 255, 255, 1);
    --hk-color-complementary: rgba(156, 163, 175, 1);
    --hk-button-base: rgba(17, 20, 28, 1);
    --hk-button-hover: rgba(14, 17, 25, 1);
    --hk-button-active: rgba(16, 20, 31, 1);
}